import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from 'aws-amplify'
import config from "./config"
import 'normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";

Amplify.configure({
    Auth: {
      region: config.cognito.REGION,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
    Storage: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
      identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
      endpoints: [
        {
          name: "sapo",
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION
        }
      ]
    }
  });


ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById("root")
);
serviceWorker.unregister();
