import * as React from 'react'
import styled, { keyframes } from 'styled-components'

class LoadingScreen extends React.Component {
    render() {
        return (
            <Aligner>
                <RotateCenter>Loading</RotateCenter>
            </Aligner>
        )
    }
}

const rotate = keyframes`
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
`;

const RotateCenter = styled.div`
  animation: ${rotate} 1s linear infinite;
  font-size: 2em;
`;

const Aligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default LoadingScreen