import * as React from 'react'
import { Grid, Cell } from "styled-css-grid"
import { withRouter } from 'react-router-dom'
import { API } from "aws-amplify"
import { addMonths } from 'date-fns'
import Main from '../Main'
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import SetupReminder from '../SetupReminder/SetupReminder'
import { AppToaster } from '../Toaster'

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userConfig: null,
            exceededDays: [],
            id: (new URL(document.location)).searchParams.get('id')
        }
    }

    componentDidMount = async () => {
        try {
            let userConfig = await this.getUserConfig();
            const exceededDays = await this.getMaxedPickupsForNMonths(6)
            this.setState({ userConfig, exceededDays });
        } catch (e) {
            if (e.toString() === 'Error: Request failed with status code 500') {
                this.showToast('No client exists for this URL.  Make sure you are using a valid URL.')
            }
            else {
                this.showToast(e.toString());
            }
        }
    }

    // n should correspond to the toMonth of the DatePicker
    getMaxedPickupsForNMonths = (n) => {
        // current Month ...
        let startDate = new Date()
        // ... to N months from current month
        let endDate = addMonths(new Date(), n)
        return API.get("sapo", "/publicPickups", {
            'queryStringParameters': {
                'id': this.state.id,
                'startDate': startDate.toISOString(),
                'endDate': endDate.toISOString()
            }
        })
    }

    getUserConfig = () => {
        return API.get("sapo", "/donorPage", {
            'queryStringParameters': {
                'id': this.state.id
            }
        })
    }

    showToast = (message) => {
        AppToaster.show({ message: message });
    }

    renderContent = (userConfig) => {
        // If userConfig is null, we are still loading the data
        if (userConfig == null) {
            return (
                <LoadingScreen />
            )
        }
        // If any of these attributes are null, client needs to finish setup
        else if (userConfig.zipcodes == null ||
            userConfig.categories == null ||
            userConfig.serviceDetails == null ||
            userConfig.restrictions == null ||
            userConfig.maxPickups == null ||
            userConfig.blackoutDates == null
        ) {
            return (
                <SetupReminder userConfig={userConfig} option='userConfig' />
            )
        }
        // If any of the email attributes are null, client needs to finish setup
        // We're only check the message body (and not the subjectLine) because
        // it's required
        else if (userConfig.submittedEmails == null ||
            userConfig.confirmedEmails == null ||
            userConfig.canceledEmails == null ||
            userConfig.completedEmails == null ||
            userConfig.rejectedEmails == null
        ) {
            return (
                <SetupReminder userConfig={userConfig} option='emails' />
            )
        }
        // else schedule page should load as normal
        else if (userConfig != null) {
            return (
                <Main {...this.props} userConfig={this.state.userConfig} id={this.state.id} exceededDays={this.state.exceededDays} />
            )
        }
    }

    render() {
        return (
            <Grid
                columns={"100fr"}
                rows={"100fr"}
                areas={[
                    "header",
                    "content",
                    "footer"
                ]}
            >
                <Cell area="content">
                    {this.renderContent(this.state.userConfig)}
                </Cell>
            </Grid>
        )
    }
}

export default withRouter(Home);