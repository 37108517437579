import * as React from 'react';
import Home from './Components/Authenticator/Home'


class App extends React.Component {
  render(){
    return(
      <Home />
    )
  }
}

export default App;

