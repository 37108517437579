import { addMonths,eachDay, getMonth, isSameDay } from 'date-fns';

const nthWeekdayOfMonth = (weekday, n, iDate) => {
    let month = iDate.getMonth();
    let date = new Date(iDate.getFullYear(), month, 1)
    let add = (weekday - date.getDay() + 7) % 7 + (n - 1) * 7;

    // make sure that we stay in the same month
    do {
        date.setMonth(month);
        date.setDate(1 + add);
        add -= 7;
    } while (date.getMonth() !== month);

    return date;
}

const nthWeekdayOfEveryMonth = (weekday, n, iDate = new Date()) => {
    let days = []
    // get this and following six months
    const months = [getMonth(iDate),
                    getMonth(addMonths(iDate,1)),
                    getMonth(addMonths(iDate,2)),
                    getMonth(addMonths(iDate,3)),
                    getMonth(addMonths(iDate,4)),
                    getMonth(addMonths(iDate,5)),
                    getMonth(addMonths(iDate,6)),
    ]
    months.forEach(month => {
        // we need to check if we approach the next year
        let newDate = new Date(iDate.getFullYear(), month, 1)
        /********************************** 
        // if the new date comes before the current date, then we need to move to next year
        // newDate should never come before iDate (the current date)
        **********************************/
        if((getMonth(iDate) !== getMonth(newDate)) && newDate < iDate) newDate = new Date(iDate.getFullYear() + 1, month, 1)
        days.push(nthWeekdayOfMonth(weekday, n, newDate))
    })
    return days;
}

const getWeekdayValues = (weekdays) => {
    return weekdays.map((day) => Object.keys(day).filter((k) => day[k] && k !== 'day'))
}

const convertOrdinalsToNumbers = (ordinals) => {
    const dayNumbers = [];
    if (ordinals.includes('all')) {
        dayNumbers.push(1, 2, 3, 4, 5)
        return dayNumbers;
    }
    if (ordinals.includes('first')) {
        dayNumbers.push(1)
    }
    if (ordinals.includes('second')) {
        dayNumbers.push(2)
    }
    if (ordinals.includes('third')) {
        dayNumbers.push(3)
    }
    if (ordinals.includes('fourth')) {
        dayNumbers.push(4)
    }
    if (ordinals.includes('fifth')) {
        dayNumbers.push(5)
    }
    return dayNumbers;
}

const convertToDates = (weekdays) => {
    let converted = getWeekdayValues(weekdays)
    converted = converted.map(date => convertOrdinalsToNumbers(date))
    let arrayOfDates = [];
    converted.map((dates, index) => dates.map(date => arrayOfDates = [...arrayOfDates, ...nthWeekdayOfEveryMonth(index, date)]))
    return arrayOfDates
}


const getDisabledDates = (weekdays) => {
    // current Month ...
    let startDate = new Date()
    // ... to Five months from current month
    let endDate = addMonths(new Date(), 6)
    let dateRange = eachDay(startDate, endDate)
    let convertedDates = convertToDates(weekdays)

    //get the difference of the two date arrays
    return [dateRange, convertedDates].sort((a,b)=> b.length - a.length).reduce((a,b)=>a.filter(o => !b.some(v => isSameDay(o,v))));
}

export default getDisabledDates;