import * as React from 'react'
import styled, { keyframes } from 'styled-components'

class SetupReminder extends React.Component {

    renderVersion = (option) => {
        if (option === 'userConfig') {
            return (
                <Aligner>
                    You need to setup the emails, zipcodes, max number of pickups, and categories
                    before you can use the scheduling page!
                </Aligner>
            )
        }
        else if (option === 'emails') {
            return (
                <Aligner>
                    You need to setup each of the email types to use the scheduling page!
                </Aligner>
            )
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.renderVersion(this.props.option)}
            </React.Fragment>
        )
    }
}

const Aligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default SetupReminder