import * as React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Schedule from './Schedule/Schedule'

class Main extends React.Component {
    render(){
      return(
        <Switch>
          <Route exact path='/'
                 render = {() => <Schedule {...this.props}/>}
          />
        </Switch>
      );
    }
  }
  
  export default withRouter(Main);