const config = {
    s3: {
        REGION: "us-east-1",
        BUCKET: "sapo-prod-uploads"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://1vz50gsavh.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-1",
        IDENTITY_POOL_ID: "us-east-1:038fbb1f-f38a-438a-8e0f-60f8c73cd29b"
    },
    HERE_APP_ID: 'u3uFI5c0XaweKx6Yh31t',
    HERE_APP_CODE: 'wUPW8ZhbclB20ZTwqRC4fA',
    GOOGLE_MAP_KEY: 'AIzaSyDOs_VPiyP8PWQ70b7uNtPhKftBgwsFhw8'
}

export default config;